import { apiQueryRate_USD_INR } from '@/resource'

export default {
  data() {
    return {
      rate: 0.0,
    }
  },
  mounted() {
    this.queryRate()
  },
  methods: {
    queryRate() {
      apiQueryRate_USD_INR()
        .then(resp => {
          if (resp.data.code == 0) this.rate = resp.data.data
        })
        .catch(err => {
          this.errorMessage(
            this.$t('deposit.default.rate.result.rateError', { oldCurrency: 'USD', newCurrency: 'INR' })
          )
        })
    },
  },
}
