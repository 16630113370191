<template>
  <div id="indiaBankTransfer">
    <div class="content_box">
      <div class="inner_max">
        <div class="page-header">{{ $t('menu.depositFund') }}</div>
        <div class="form_deposit" v-if="formFlag">
          <h4>{{ $t('common.withdrawChannel.indiabanktransfer') }}</h4>
          <div class="content">
            <el-form
              label-position="top"
              :model="indiaBankTransferForm"
              ref="indiaBankTransferForm"
              status-icon
              :rules="indiaBankTransferRules"
            >
              <div class="box box_vertical">
                <strong class="title">
                  {{ $t('deposit.default.deposit') }}
                </strong>
                <div class="form_content_box">
                  <div class="info">
                    <p>
                      {{ $t('deposit.intSwift.desc', { platform: $platform.info.fullName }) }}
                    </p>
                  </div>

                  <ul class="bank_info bank_info_vertical clearfix">
                    <li v-bind:key="item.title" v-for="item in bankInfos">
                      <span class="title_name">{{ $t(item.title) }}</span>
                      <span class="value_info">{{ item.info }}</span>
                    </li>
                  </ul>
                  <div class="p-2 mb-5 text-left text-magenta border border-magenta warn_info">
                    <p>{{ $t('deposit.intSwift.refInfo2') }}</p>
                    <p>{{ $t('deposit.intSwift.refInfo', { platform: $platform.info.fullName }) }}</p>
                  </div>
                </div>
              </div>
              <div class="box box_vertical">
                <strong class="title">{{ $t('deposit.default.sendReceipt') }} </strong>
                <div class="form_content_box">
                  <div class="info mb-4">
                    <p>
                      Once you have made the transfer, please complete the form below and attach a copy of the bank
                      transfer receipt.
                    </p>
                  </div>
                  <div class="form_list">
                    <ul class="clearfix">
                      <li class="fl">
                        <el-form-item :label="$t('common.field.accNum')" prop="accountNumber">
                          <el-select v-model="indiaBankTransferForm.account" data-testid="accountNumber">
                            <el-option
                              v-for="item in indiaBankTransferForm.accountNumberOptions"
                              :key="item.value"
                              :value="item.value"
                              :data-testid="item.value"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </li>
                      <li class="fl">
                        <el-form-item label="Amount" prop="amount">
                          <numeric-input
                            v-model="indiaBankTransferForm.amount"
                            :currency="indiaBankTransferForm.currency"
                            :precision="2"
                          ></numeric-input>
                        </el-form-item>
                      </li>
                    </ul>
                    <ul class="clearfix">
                      <li class="fr">
                        <el-form-item :label="$t('common.field.notes')">
                          <el-input v-model="indiaBankTransferForm.notes" data-testid="applicationNotes"></el-input>
                        </el-form-item>
                      </li>
                      <li class="fr data">
                        <p class="amountRate">
                          {{
                            $t('deposit.default.rate.rate', {
                              oldCurrency: accountCurrency ? accountCurrency : 'USD',
                              newCurrency: 'INR'
                            })
                          }}<span>{{ rate }}</span>
                        </p>
                        <p>
                          INR: <span>{{ rateChange }}</span>
                        </p>
                      </li>
                    </ul>
                    <ul class="clearfix">
                      <li class="fl">
                        <el-form-item :label="$t('common.field.upload')" prop="uploadFile">
                          <vUpload
                            :limit="6"
                            v-on:updateFileInfo="updateFileInfo"
                            data-testid="updateFileInfo"
                          ></vUpload>
                        </el-form-item>
                      </li>
                      <li>
                        <p>{{ $t('deposit.indiaBankTransfer.desc') }}</p>
                        <p>{{ $t('deposit.indiaBankTransfer.bussDayInfo') }}</p>
                      </li>
                    </ul>
                  </div>
                  <div class="button">
                    <el-button type="primary" :loading="loading" @click="submitForm()" data-testid="submit">
                      {{ $t('common.button.submit') }}
                    </el-button>
                  </div>
                </div>
              </div>
            </el-form>
          </div>
        </div>
        <Result v-if="successFlag">{{ $t('deposit.default.successMsg') }}</Result>
      </div>
    </div>
  </div>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import vUpload from '@/components/vUpload';
import Result from '@/components/Result';
import India from '@/bankInfo/vfsc/India';
import mixin from '@/mixins/page/deposit';
import indiaMixin from '@/mixins/page/deposit/india';
import rounding from '@/util/rounding';
import { apiQuery_mt_accounts, apiIndiaBankTransferSubmitRecipt } from '@/resource';

export default {
  name: 'IndiaBankTransfer',
  components: { vUpload, NumericInput, Result },
  mixins: [mixin, indiaMixin],
  data() {
    const checkUploadFile = (rule, value, callback) => {
      if (!(this.fileList.length > 0)) callback(new Error(this.$t('common.formValidation.fileReceipt')));
      else callback();
    };
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (parseFloat(value) < this.minLimit) {
        callback(new Error(this.$t('common.formValidation.amtLarger', { minLimit: this.minLimit })));
      } else if (parseFloat(value) > this.maxLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess', {
              maxLimit: this.maxLimit,
              currency: this.indiaBankTransferForm.currency
            })
          )
        );
      } else {
        callback();
      }
    };
    return {
      indiaBankTransferForm: {
        account: '',
        accountNumberOptions: [],
        amount: '',
        accountNumber: '',
        currency: '',
        notes: ''
      },
      indiaBankTransferRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ],
        uploadFile: [
          {
            validator: checkUploadFile,
            required: true,
            trigger: 'change'
          }
        ]
      },
      minLimit: 50,
      fileList: [],
      formFlag: true,
      successFlag: false
    };
  },
  watch: {
    'indiaBankTransferForm.account'(value) {
      const values = value.split(' - ');
      this.indiaBankTransferForm.accountNumber = values[0];
      this.indiaBankTransferForm.currency = values[1];
    },
    'indiaBankTransferForm.accountNumberOptions'(options) {
      // 預設第一筆
      if (options.length > 0) this.indiaBankTransferForm.account = options[0].value;
    }
  },
  methods: {
    updateFileInfo(fileInfo) {
      this.fileList = fileInfo.fileList;
      this.$refs['indiaBankTransferForm'].validateField('uploadFile');
    },
    submitForm() {
      this.$refs['indiaBankTransferForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              if (result.data.data) {
                this.formFlag = false;
                this.successFlag = true;
              } else {
                this.loading = false;
                this.errorMessage(this.$t('deposit.default.failed'));
              }
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        } else {
          return false;
        }
      });
    },
    fetchTradingAccount() {
      apiQuery_mt_accounts({ supportedCurrencies: 'USD' }).then(resp => {
        if (resp.data.code == 0) {
          this.indiaBankTransferForm.accountNumberOptions = resp.data.data.map(i => {
            return {
              accountNumber: i.mt4_account,
              value: i.mt4_account + ' - ' + i.currency,
              currency: i.currency
            };
          });
        }
      });
    },
    submitDeposit() {
      return apiIndiaBankTransferSubmitRecipt(
        {
          mt4Account: this.indiaBankTransferForm.accountNumber,
          operateAmount: this.indiaBankTransferForm.amount,
          depositAmount: this.rateChange,
          applicationNotes: this.indiaBankTransferForm.notes,
          rate: this.rate,
          fileList: this.fileList
        },
        this.token
      );
    }
  },
  mounted() {
    this.fetchTradingAccount();
  },
  computed: {
    bankInfos() {
      return India.bank_infos();
    },
    rateChange() {
      return rounding(Math.floor, this.indiaBankTransferForm.amount * this.rate, 2);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/internationalAndAu.scss';

.box {
  width: 100% !important;
}
</style>
